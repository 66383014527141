import {
  ActionIcon,
  Center,
  Flex,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Table,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { TitlePage } from "../../../components/TitlePage";
import { formatFloatToBrl } from "../../../helpers/formatFloatToBrl";
import { transformDateV2 } from "../../../helpers/transformDateV2";
import movimentacao from "../../../services/movimentacao";
import { useParams } from "react-router-dom";



export default function MovimentacoesManuais() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    movimentacao
      .all({carteira: id})
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const deleteItem = async (id: number) => {
    setLoading(true);
    movimentacao
      .delete(id)
      .then((res: AxiosResponse<any>) => {
        setLoading(false);
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };

  const rows = data?.data.map((element: any, idx: any) => (
    <tr key={element.id}>
      <td>{element.carteira}</td>
      <td>{transformDateV2(element.data)}</td>
      <td>{formatFloatToBrl(element.grossvalue)}</td>
      <td>{element.movementhistory}</td>
      <td>{element.origem}</td>
      <td>
        <Center>
          <ActionIcon>
            <IconTrash
              color="red"
              size={18}
              stroke={1}
              onClick={() => deleteItem(element.id)}
            />
          </ActionIcon>
        </Center>
      </td>
    </tr>
  ));

  return (
    <>
      <SimpleGrid cols={1}>
      <Flex w={"100%"} justify="space-between" align={"center"}>
        <TitlePage title={`Movimentações Manuais`} />
         <Flex gap={"xs"}>
          </Flex>
        </Flex>

        <Flex w={"100%"} align={"center"} justify="center" direction={"column"}>
          <div style={{ width: "100%", position: "relative" }}>
           <LoadingOverlay visible={loading} overlayBlur={2} />
            <Table striped highlightOnHover withColumnBorders fontSize={12}>
              <thead>
                <tr>
                  <th>Carteira</th>
                  <th>Data</th>
                  <th>Gross</th>
                  <th>Movement History</th>
                  <th>Origem</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>

          <Flex p="xs" justify={"center"} w={"100%"} align={"flex-end"}></Flex>
        </Flex>
      </SimpleGrid>
    </>
  );
}
