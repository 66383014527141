import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  TextInput,
  MultiSelect
} from "@mantine/core";
import  {CheckBox}  from '../../../components/CheckBox'
import { DatePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconWallet } from "@tabler/icons";
import { useEffect, useState } from "react";
import { TitleBasic } from "../../../components/TitleBasic";
import carteira from "../../../services/carteira";
import { InputMask } from "../../../components/InputMask/MaskInput";
import { MenuOpcoesCarteiras } from "../../../components/MenuOpcoesCarteiras";
import { Controller, useForm } from "react-hook-form";
import { InputMaskHookForm } from "../../../components/InputMask/MaskInput_hook_form"
import MultiSelectHookForm from "../../../components/MultiSelect";
import { transformDate } from "../../../helpers/transformDate";
import { InputMaskQtd } from "../../../components/InputMask/MaskInputQtd";

export default function NovaCarteiraModal() {
  const [opened, { open, close }] = useDisclosure(false);
  const [carteiras, setCarteiras] = useState<any>([]);
  const [loading, setLoading] = useState(false)

  const { control, handleSubmit, watch, register, reset, setValue, getValues } = useForm<any>({
    defaultValues: {
      nome: "",
      documento: "",
      carteira: "",
      data_inicial: "",
      corretora: "",
      link_scrap: "",
      pl_inicial: "0",
      qtd_cotas: "0",
      provisao_e_adm: false,
      adm_acumulado: "0",
      provisao: "0",
      carteiras_filhos: []
    },
  });

  useEffect(() => {
    const fetchCarteiras = async () => {
      try {
        const data = await carteira.get();
        
        const carteirasData = data.data.data.map((carteira: any) => ({
          value: carteira.n_carteira,
          label: `${carteira.n_carteira} - ${carteira.nome}`
        }));

        setCarteiras(carteirasData); 
      } catch (error) {
        console.log('erro ao recuperar lista de carteiras')
      } 
    };

    fetchCarteiras();
  }, []);

  const onSubmit = (data:any) => {
    console.log(data);

  };

  const corretora = watch("corretora");
  const provisao_e_adm = watch("provisao_e_adm");


  useEffect(() => {
  if (corretora !== "Xp") {
      setValue("link_scrap", ""); 
    }

  if(!provisao_e_adm){
    setValue("provisao", "0"); 
    setValue("adm_acumulado", "0"); 
  }

  if(corretora === "Consolidada"){
    setValue('carteiras_filhos', [])
  }

  }, [corretora, setValue]);

  async function handlePostData(data:any) {
    setLoading(true);
    showNotification({
      id: "load-data",
      loading: true,
      title: "Cadastrando nova Carteira",
      message: "Aguarde...",
      autoClose: true,
      disallowClose: true,
    });


      const dataModified = {
      ...data,
      data_inicial: await transformDate(data.data_inicial),
    };

      const response = await carteira.post(dataModified);

      if(response?.status !== 200) {
        updateNotification({
          id: "load-data",
          title: "Erro!",
          message: `Ocorreu erro ao criar nova carteira!`,
          autoClose: 2000,
          color: "red",
        });
        return
      }
  
      updateNotification({
        id: "load-data",
        color: "teal",
        title: "Sucesso!",
        message: `Carteira Cadastrada com Sucesso!`,
        autoClose: 2000,
      });

  }
  return (
    <>
       <Modal
      opened={opened}
      size="md"
      onClose={close}
      dir="row"
      withCloseButton={false}
    >
      <form onSubmit={handleSubmit(handlePostData)}>
        <TitleBasic title="Adicionar Nova Carteira" />
        <SimpleGrid cols={2} breakpoints={[{ maxWidth: "sm", cols: 2 }]}>
          <Controller
            name="nome"
            control={control}
            rules={{ required: "Nome é obrigatório" }}
            render={({ field, fieldState }) => (
              <TextInput
                label="Nome do Cliente"
                placeholder="Digite nome"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />

        <Controller
            name="documento"
            control={control}
            rules={{ required: "Documento é obrigatório" }}
            render={({ field, fieldState }) => (
              <TextInput
                label="Documento"
                placeholder="Digite nome"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />

        <Controller
            name="carteira"
            control={control}
            rules={{ required: "Carteira é obrigatório" }}
            render={({ field, fieldState }) => (
              <TextInput
                label="Carteira"
                placeholder="Digite nome"
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />

        <Controller
            name="data_inicial"
            control={control}
            rules={{ required: "Data é obrigatório" }}
            render={({ field, fieldState }) => (

            <DatePicker
            label="Data Inicial"
            fullWidth
            placeholder="Data Inicial"
            withAsterisk
            error={fieldState.error?.message}
            {...field}
          />
            )}
          />

          <Controller
            name="corretora"
            control={control}
            rules={{ required: "Corretora é obrigatório" }}
            render={({ field, fieldState }) => (
              <Select
                label="Corretora"
                placeholder="Selecione uma corretora"
                data={[
                  { value: "Xp", label: "XP" },
                  { value: "BTG Pactual", label: "BTG" },
                  { value: "Consolidada", label: "Consolidada" },
                ]}
                error={fieldState.error?.message}
                {...field}
              />
            )}
          />

        {corretora === "Xp" && (
          <Controller
            name="link_scrap"
            control={control}
            rules={{ required: "Link Scrap é obrigatório" }}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                label="Link Scrap"
                placeholder="Link Scrap"
                {...field}
                error={error?.message} 
              />
            )}
          />
        )}

        <Controller
            name="pl_inicial"
            control={control}
            rules={{ required: "PL é obrigatório" }}
            render={({ field, fieldState }) => (
            <InputMaskHookForm
              label="PL Inicial"
              placeholder="R$"
              error={fieldState.error?.message}
              {...field}
          />
            )}
          />

        <Controller
            name="qtd_cotas"
            control={control}
            rules={{ required: "Qtd Cotas é obrigatório" }}
            render={({ field, fieldState }) => (
              <InputMaskQtd
                label="Quantidade de cotas"
                placeholder="Quantidade de Cotas"
                {...field}
              />
            )}
          />

      <Controller
            name="provisao_e_adm"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex self-end align-end">
              <CheckBox 
              control={control}
              label="Adm Acumulado e Provisão?"
              {...field} />
              </div>
            )}
          />

        {provisao_e_adm ? (
            <>
              <Controller
                name="adm_acumulado"
                control={control}
                rules={{ required: provisao_e_adm ? "ADM Acumulado é obrigatório" : false }}
                render={({ field, fieldState }) => (
                  <InputMaskHookForm
                    label="TX Adm Acumulado"
                    placeholder="R$"
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
              />

              <Controller
                name="provisao"
                control={control}
                rules={{ required: provisao_e_adm ? "Provisao é obrigatório" : false }}
                render={({ field, fieldState }) => (
                  <InputMaskHookForm
                    label="Provisão"
                    placeholder="R$"
                    error={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </>
          ) : (
            <></>
          )}

        {corretora === "Consolidada" ? (
            <>
            <Controller
            name="carteiras_filhos"
            rules={{
              validate: (value) => {
                if (corretora === "Consolidada") {
                  return value.length >= 1 || 'Selecione pelo menos uma carteira';
                }
                return true;
              },
            }}
            control={control}
            render={({ field, fieldState }) => (
              <MultiSelectHookForm
                label="Carteiras Filhos"
                placeholder="Selecione suas carteiras"
                data={carteiras}
                {...field}
                error={fieldState?.error?.message}
                control={control}
              />
            )}
          />
         
            </>
            
          ) : (
            <></>
          )}

          
        </SimpleGrid>
        <Button fullWidth size="md" mt="xs" type="submit">
          Cadastrar Cliente
        </Button>
      </form>
    </Modal>

      <Group position="center">
        <Button leftIcon={<IconWallet />} onClick={open}>
          Nova Carteira
        </Button>
        <MenuOpcoesCarteiras />
      </Group>
   
    </>
  );
}
