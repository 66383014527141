import { InputBase } from "@mantine/core";
import "cleave.js/dist/addons/cleave-phone.br";
import CurrencyInput from "react-currency-input-field";

interface InputMaskPercentageProps {
  label?: string;
  onChange: (value: number | null | string) => void;
  placeholder?: string;
  defaultValue?: string;
  error?: string;
}

export function InputMaskHookForm({
  label,
  onChange,
  defaultValue,
  placeholder,
  error,
}: InputMaskPercentageProps) {
  return (
    <InputBase
      label={label}
      component={CurrencyInput}
      defaultValue={defaultValue}
      placeholder={placeholder}
      prefix="R$"
      groupSeparator="."
      decimalsLimit={2}
      decimalSeparator=","
      error={error}
      onValueChange={(value) => {
        const numericValue = value ? parseFloat(value.replace(",", ".")) : null;
        onChange(numericValue);
      }}
    />
  );
}
