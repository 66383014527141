import React from 'react';
import { MultiSelect } from '@mantine/core';
import { Controller, Control, FieldValues } from 'react-hook-form';

interface MultiSelectHookFormProps {
  label: string;
  name: string;
  control: Control<FieldValues>; // Control como propriedade
  data: string[]; // Array de opções para o MultiSelect
  placeholder?: string;
  error?: string;
}

const MultiSelectHookForm: React.FC<MultiSelectHookFormProps> = ({
  label,
  name,
  control,
  data,
  placeholder,
  error,
}) => {
  return (
    <Controller
      name={name} // Nome do campo no formulário
      control={control} // Passando o control para o Controller
      render={({ field }) => (
        <MultiSelect
          {...field} // Passando as propriedades do field para o MultiSelect
          label={label}
          placeholder={placeholder || 'Selecione'}
          data={data}
          error={error}
          style={{ gridColumn: 'span 2', width: '100%' }}
          value={field.value || []} // Mantém o valor do campo
          onChange={(value) => field.onChange(value)} // Atualiza o valor do campo no react-hook-form
        />
      )}
    />
  );
};

export default MultiSelectHookForm;
